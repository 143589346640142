import React, { Suspense, useState, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import Root from './Root';
import LoadingComponent from '../components/Loader';
import { SidebarProvider } from '../context/sidebarContext';
import { WindowSizeProvider } from '../context/windowSizeContext';

const Topbar = React.lazy(() => import('../components/topbar'));
const Login = React.lazy(() => import('../pages/auth/login'));
const Dashboard = React.lazy(() => import('../pages/dashboard/dashboard'));
const Employees = React.lazy(() => import('../pages/employee/employee'));
const Agency = React.lazy(() => import('../pages/agency/agency'));
const Driver = React.lazy(() => import('../pages/driver/driver'));
const Vehicle = React.lazy(() => import('../pages/vehicle/vehicle'))
const Location = React.lazy(() => import('../pages/location/location'))
const GPSDevice = React.lazy(() => import('../pages/gpsDevice/gpsDevice'))


// Not In Use 




type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => {

    return (
        <Suspense fallback={<LoadingComponent />}>
            <Component />
        </Suspense>
    )
};


// HOC for Navbar
const NavbarComponent = ({ component: Component }: LoadComponentProps) => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        // Function to update window size
        const updateSize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        // Listen for resize events
        window.addEventListener('resize', updateSize);
        // Remove event listener on component unmount
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (

        <Suspense fallback={<LoadingComponent />}>
            <SidebarProvider>
                <WindowSizeProvider>
                    <div className='wrapper' style={{
                        width: windowSize.width,
                        height: windowSize.height,
                    }}>
                        <Topbar />
                        <Component />
                    </div>
                </WindowSizeProvider>

            </SidebarProvider>
        </Suspense>
    )
};

const AllRoutes = () => {
    return useRoutes(

        [
            { path: '/', element: <Root /> }, // DON'T REMOVE this is used to navigate user to respective panel after login 
            {
                // public routes
                path: '/',
                children: [
                    {
                        path: '',
                        children: [

                            { path: 'login', element: <LoadComponent component={Login} /> },
                        ],
                    },
                ],
            },
            {
                // auth protected routes
                path: '/',
               // element: <Root />,
                children: [
                    { path: 'dashboard', element: <NavbarComponent component={Dashboard} /> },
                    { path: 'employees', element: <NavbarComponent component={Employees} /> },
                    { path: 'agency', element: <NavbarComponent component={Agency} /> },
                    { path: 'driver', element: <NavbarComponent component={Driver} /> },
                    { path: 'vehicle', element: <NavbarComponent component={Vehicle} /> },
                    { path: 'location', element: <NavbarComponent component={Location} /> },
                    { path: 'gps-device', element: <NavbarComponent component={GPSDevice} /> },
                ]
            },
        ],




    );
};
export { AllRoutes };
