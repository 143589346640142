import React, { createContext, useContext, useState, useEffect } from 'react';

// Step 1: Create a new context for the window size
const WindowSizeContext = createContext();

// Step 2: Create a component to provide the window size context
export const WindowSizeProvider = ({ children }) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const updateSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <WindowSizeContext.Provider value={windowSize}>
      {children}
    </WindowSizeContext.Provider>
  );
};

// Step 3: Create a custom hook to consume the window size context
export const useWindowSize = () => {
  return useContext(WindowSizeContext);
};
